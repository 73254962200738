






import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        PagePresetFullWidth: () => import('@/layouts/presets/FullWidth.vue'),
        SupervisorOverview: () => import('@/components/user/SupervisorOverview.vue'),
    },
})
export default class PageSupervisors extends Vue {
    i18n: string = 'views.supervisors';
}
